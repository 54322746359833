import { createContext, ReactNode, useEffect, useState } from 'react';

import * as auth from 'src/user';

type AuthContextAuthObject = {
  token: ReturnType<typeof auth.getToken>;
  userCode: ReturnType<typeof auth.getUserCode>;
  isLoggedIn: ReturnType<typeof auth.isLoggedIn>;
  shouldAcceptTOS: ReturnType<typeof auth.shouldAcceptTOS>;
  guestInfo: ReturnType<typeof auth.getGuestInfo>;
  switchGuestInfos: ReturnType<typeof auth.getSwitchGuestInfos>;
};
type AuthContextValue =
  | undefined
  | {
      auth?: AuthContextAuthObject;
      signin: typeof auth.setLoggedIn;
      acceptTOS: typeof auth.acceptTOS;
      signout: typeof auth.signout;
      setPictureUrl: typeof auth.setPictureUrl;
      hasGuestTypeChanged: boolean;
      resetGuestTypeChanged: () => void;
    };

type AuthContextProviderProps = {
  children: ReactNode;
};

export const AuthContext = createContext<AuthContextValue>(undefined);

export function AuthContextProvider({ children }: AuthContextProviderProps) {
  const [isAuthResolved, setIsAuthResolved] = useState(false);
  const [authObject, setAuth] = useState<AuthContextAuthObject>();
  const [hasGuestTypeChanged, setHasGuestTypeChanged] = useState(false);

  useEffect(() => {
    setAuth(buildAuthFromLocalStorage());
    setIsAuthResolved(true);
  }, []);

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === `${auth.getUserCode()}:${auth.STORAGE_KEY.GUEST_INFO}`) {
        const newAuth = buildAuthFromLocalStorage();
        setAuth(newAuth);
        if (newAuth.guestInfo?.type !== authObject?.guestInfo?.type) {
          setHasGuestTypeChanged(true);
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [authObject?.guestInfo?.type]);

  return (
    <AuthContext.Provider
      value={{ auth: authObject, signin, acceptTOS, signout, setPictureUrl, hasGuestTypeChanged, resetGuestTypeChanged }}
    >
      {isAuthResolved && children}
    </AuthContext.Provider>
  );

  function signin(guestInfo: auth.GuestInfo, token: string, switchGuestInfos: auth.SwitchGuestInfo) {
    auth.setLoggedIn(guestInfo, token, switchGuestInfos);
    setAuth(buildAuthFromLocalStorage());
  }

  function acceptTOS() {
    auth.acceptTOS();
    setAuth(buildAuthFromLocalStorage());
  }

  function signout() {
    auth.signout();
    setAuth(buildAuthFromLocalStorage());
  }

  function setPictureUrl(url: string) {
    auth.setPictureUrl(url);
    setAuth(buildAuthFromLocalStorage());
  }

  function resetGuestTypeChanged() {
    setHasGuestTypeChanged(false);
  }
}

function buildAuthFromLocalStorage() {
  const guestInfo = auth.getGuestInfo();
  // Override type, such as useAuth().type matches getGuestType() (i.e. be lowercase)
  if (guestInfo?.type) {
    guestInfo.type = auth.getGuestType() as auth.GuestType;
  }

  return {
    token: auth.getToken(),
    userCode: auth.getUserCode(),
    isLoggedIn: auth.isLoggedIn(),
    shouldAcceptTOS: auth.shouldAcceptTOS(),
    guestInfo,
    switchGuestInfos: auth.getSwitchGuestInfos(),
  };
}
