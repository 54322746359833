import { createContext, useCallback, useContext, useState } from 'react';

export const ConnectionLogData = createContext({});

export const ConnectionLogDataProvider = ({ children }) => {
  const [connectionLogState, setconnectionLogState] = useState();

  return <ConnectionLogData.Provider value={{ connectionLogState, setconnectionLogState }}>{children}</ConnectionLogData.Provider>;
};

export const useAddToconnectionLogState = () => {
  const { setconnectionLogState } = useContext(ConnectionLogData);
  return useCallback(
    props => {
      setconnectionLogState(prev => ({ ...prev, ...props }));
    },
    [setconnectionLogState]
  );
};
